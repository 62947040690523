import { RegisterOptions } from 'react-hook-form';

/**
 * Create a reusable validation function for matching patterns.
 * @param pattern Regex pattern to validate the input.
 * @param defaultMessage Default error message if validation fails.
 * @returns RegisterOptions['pattern']
 */
export const createPatternValidator = (
  pattern: RegExp,
  defaultMessage: string
): RegisterOptions['pattern'] => {
  return {
    value: pattern,
    message: defaultMessage,
  };
};

// Specific validators using the common function

export const isValidName = (invalidMessage?: string): RegisterOptions['pattern'] =>
  createPatternValidator(
    /^[a-zA-Z\s]+$/,
    invalidMessage || 'Please enter a valid name (letters and spaces only)'
  );

export const isValidSubject = (invalidMessage?: string): RegisterOptions['pattern'] =>
  createPatternValidator(
    /^[a-zA-Z\s]+$/,
    invalidMessage || 'Please enter a valid subject (letters and spaces only)'
  );

export const isValidDescription = (invalidMessage?: string): RegisterOptions['pattern'] =>
  createPatternValidator(
    /^[a-zA-Z0-9\s.,'-]+$/,
    invalidMessage || 'Please enter a valid description (letters, numbers, spaces, and basic punctuation only)'
  );

export const isValidEmail = (invalidMessage?: string): RegisterOptions['pattern'] =>
  createPatternValidator(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    invalidMessage || 'Please enter a valid email address'
  );


