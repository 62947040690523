import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

// Hooks
import { useDispatch, useSelector } from '../store';
import useFlow from '../hooks/useFlow';
import useTranslations from '../hooks/useTranslations';

// Redux
import { hideModal } from '../store/reducers/modalReducer';
import { GlobalModals } from '../store/initial/modal';
import userSelector from '../store/selectors/userSelector';
import userPersonSelector from '../store/selectors/userPersonSelector';

// Components
import TextField from './TextField';
import Form from './Form';
import Button from './Button';
import { TextArea } from './TextArea';
import Modal from './Modal';
import Flow from './Flow';

// Types
import SanitizedFormData from '../../core/types/SanitizedFormData';

// Utils
import { fieldRequired, isValidEmail, isValidDescription, isValidName, isValidSubject } from '../formValidators';
import inputMask from '../utils/inputMask';
import appConfig from '../../core/appConfig';
import Translation from './Translation';

export interface HelpFormData extends SanitizedFormData {
    name: string;
    email: string;
    phone: string;
    subject: string;
  }

export default function HelpModal(): JSX.Element{
  const isOpen = useSelector( state => state.modal.data.help.isOpen );

  const user = useSelector( userSelector );
  const userPerson = useSelector( userPersonSelector );

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const [ isPending, setIsPending ] = useState( false );
  const { getTranslation } = useTranslations();

  const { currentIndex, retreat, proceed } = useFlow();

  const { orgid, baseUrl } = appConfig.salesforce;

  const defaultValues = {
    name: userPerson?.name || '',
    email: user.email || '',
    phone: user.phone || '',
  };

  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm<HelpFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const sanitizeFormData = (data: SanitizedFormData): HelpFormData => {
    return {
      name: sanitizeHtml(data.name || ''),
      email: sanitizeHtml(data.email || ''),
      phone: sanitizeHtml(data.phone || ''),
      subject: sanitizeHtml(data.subject || ''),
      description: sanitizeHtml(data.description || ''),
    };
  };

  const useFormProps = {
    errors,
    getValues,
    register,
    setValue,
  };

  /**
   * Parse the submitted data and add to it
   * - Create an iFrame
   * - Create a Form
   * - Add data to the form
   * - Set the form's target to the iFrame
   * - Submit the form/iFrame
   *
   * Salesforce Web2Case API does not allow for client-side form
   * submission via JS. While hacky, this seems like best option.
   * If this proves brittle, we can look at using the REST API to
   * create cases.
   */
  const handleOnSubmit: SubmitHandler<SanitizedFormData> = async data => {
    setIsPending( true );
    // provide a little context on where form was rendered
    const sanitizedData: HelpFormData = sanitizeFormData(data)

    console.log("original data is: ", data, " and sanitized data is: ", sanitizedData)

    const description = `${sanitizedData.description}
      -- sent from url: ${pathname}
      -- name: ${sanitizedData.name}
      -- phone: ${sanitizedData.phone}
      -- email: ${sanitizedData.email}`;
    Object.assign( sanitizedData, {
      orgid,
      retURL: 'https://ethoslife.com',
      description,
    });
    const customHiddenIframeName='salesforce_case';
    if( !document.getElementById( customHiddenIframeName )){
      const theiFrame=document.createElement( 'iframe' );
      theiFrame.id=customHiddenIframeName;
      theiFrame.name=customHiddenIframeName;
      theiFrame.src='about:blank';
      theiFrame.style.display='none';
      document.body.appendChild( theiFrame );
    }
    const form = document.createElement( 'form' );
    form.method = 'POST';
    form.action = new URL( '/servlet/servlet.WebToCase?encoding=UTF-8', baseUrl ).toString();
    form.setAttribute( 'target', customHiddenIframeName );
    for ( const fieldName in sanitizedData ) {
      const theInput = document.createElement( 'input' );
      theInput.name=fieldName;
      theInput.value=sanitizedData[fieldName] || '';
      theInput.setAttribute( 'type', 'hidden' );
      form.appendChild( theInput );
    }
    document.body.appendChild( form );
    form.submit();

    setIsPending( false );
    proceed();
  };

  return (
    <Modal isOpen={ isOpen }
      onClose={ ()=>{
        dispatch( hideModal( GlobalModals.help ));
      } }>
      <Flow currentIndex={ currentIndex }>
        <Form handleSubmit={ handleSubmit } onSubmit={ handleOnSubmit }>
          <h1 className="font-headline text-3xl mb-6 text-black"><Translation translationKey="support.form.title"/></h1>
          <TextField { ...useFormProps }
            className={ 'flex-1 mr-4' }
            name="name"
            required={ fieldRequired( getTranslation( 'support.form.name.required' )) }
            pattern={isValidName()}
            label={ getTranslation( 'support.form.name.label' ) }
            minLength={{
              value: 2,
              message: 'name must be at least 2 characters',
            }}
            maxLength={ 80 }
            placeholder={ getTranslation( 'support.form.name.placeholder' ) }
          />

          <TextField { ...useFormProps }
            className={ 'flex-1 mr-4' }
            name="email"
            required={ fieldRequired( getTranslation( 'support.form.email.required' )) }
            pattern={isValidEmail()}
            label={ getTranslation( 'support.form.email.label' ) }
            maxLength={ 80 }
            placeholder={ getTranslation( 'support.form.email.placeholder' ) }
          />

          <TextField { ...useFormProps }
            className={ 'flex-1 mr-4' }
            name="phone"
            required={ fieldRequired( getTranslation( 'support.form.phone.required' )) }
            minLength={{
              value: 11,
              message: 'Phone number must be at least 11 characters',
            }}
            label={ getTranslation( 'support.form.phone.label' ) }
            normalize={ inputMask.phone }
            placeholder={ getTranslation( 'support.form.phone.placeholder' ) }
          />

          <TextField { ...useFormProps }
            className={ 'flex-1 mr-4' }
            name="subject"
            required={ fieldRequired( getTranslation( 'support.form.subject.required' )) }
            pattern={isValidSubject()}
            label={ getTranslation( 'support.form.subject.label' ) }
            maxLength={ 80 }
            placeholder={ getTranslation( 'support.form.subject.placeholder' ) }
          />

          <TextArea
            { ...useFormProps }
            label={ getTranslation( 'support.form.description.label' ) }
            name="description"
            required={ fieldRequired( getTranslation( 'support.form.description.required' )) }
            pattern={isValidDescription()}
            maxLength={ 250 }
            placeholder={ getTranslation( 'support.form.description.placeholder' ) }
          />
          <br/>

          <Button type="submit" theme="primary" isPending={ isPending }><Translation translationKey="support.form.submit.label"/></Button>
        </Form>
        <div className="flex flex-col items-center min-h-[250px] gap-4">
          <div className="w-[100px] h-[100px] mx-auto mb-8">
            <img src={ getTranslation( 'support.success.image' ) } alt="Thumbs Up" />
          </div>
          <p><Translation translationKey="support.success.text"/></p>
          <Button theme="primary" onClick={ () => dispatch( hideModal( GlobalModals.help )) }><Translation translationKey="support.success.close" /></Button>
          <Button theme="clean" onClick={ () => retreat() }><Translation translationKey="support.success.resubmit" /></Button>
        </div>
      </Flow>
    </Modal>
  );
}
